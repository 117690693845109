/* autoprefixer grid: autoplace */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid transparent;
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid transparent;
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: 0;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 13px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -13px;
    margin-left: -13px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 13px;
    margin-bottom: 0;
    margin-left: 13px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 13px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.card-img-top {
  overflow: hidden;
}

.card-img-top picture,
.card-img-top img {
  width: 100%;
  height: auto;
}

.card-body > *:first-child {
  margin-top: 0;
}

.card-body > *:last-child {
  margin-bottom: 0;
}

.accordion-item {
  margin-top: 0.25rem;
  border-bottom: 1px solid transparent !important;
}

/*.accordion-item + .accordion-item {
    margin-top: 0.25rem;
}*/
.accordion-header {
  padding: 0;
}

.accordion-header .btn-link {
  position: relative;
  width: 100%;
  padding: 1rem calc(1rem + 2em) 1rem 1rem;
  text-align: left;
  color: #002a5b;
}

.accordion-header .btn-link:hover, .accordion-header .btn-link:focus {
  color: #001c3c;
  text-decoration: none;
}

.accordion-header .btn-link:focus {
  outline: 0;
  box-shadow: inset 0 0 0 0.16rem rgba(0, 42, 91, 0.2);
}

.accordion-header .accordion-header-icon {
  position: absolute;
  height: 0.8em;
  width: 0.8em;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
}

.accordion-header .accordion-header-icon:before, .accordion-header .accordion-header-icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  transition: all 0.2s ease-in-out;
}

.accordion-header .accordion-header-icon:before {
  transform: rotate(-45deg);
}

.accordion-header .accordion-header-icon:after {
  transform: rotate(45deg);
}

.accordion-header .btn-link.collapsed .accordion-header-icon:before {
  transform: rotate(0);
}

.accordion-header .btn-link.collapsed .accordion-header-icon:after {
  transform: rotate(90deg);
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.accordion-content-item {
  flex-grow: 1;
  order: 1;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.accordion-content-text *:last-child {
  margin-bottom: 0;
}

.accordion-body {
  border-top: 1px solid transparent;
}

.accordion-content-right,
.accordion-content-left {
  flex-direction: row;
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-item,
  .accordion-content-left .accordion-content-item {
    width: 50%;
  }
}

.accordion-content-below .accordion-content-gallery,
.accordion-content-right .accordion-content-gallery {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-gallery {
    margin-top: 0;
  }
}

.card-group-element {
  margin: -13px;
  margin-bottom: -13px !important;
  display: flex;
  flex-wrap: wrap;
}

.card-group-element-item {
  width: 100%;
  padding: 26px 13px;
}

.card-group-element-item .card {
  height: 100%;
  border: 0;
  overflow: hidden;
}

.card-group-element-item .card-img-top {
  margin-bottom: 1.125rem;
}

@media (min-width: 992px) {
  .card-group-element-item .card-img-top {
    margin-bottom: 2.625rem;
  }
}

.card-group-element-item .card-body {
  position: relative;
  margin-left: 25%;
  padding: 0 0 0.25rem;
}

.card-group-element-item .card-body::before {
  content: "";
  position: absolute;
  height: 0;
  border-top: 1px solid;
  top: 0.875rem;
  width: 100%;
  right: 100%;
  margin-right: 1rem;
}

.card-group-element-item .card-title {
  margin-bottom: 1rem;
}

.card-group-element-item .card-subtitle {
  margin-bottom: 1rem;
}

.card-group-element-item .card-title + .card-subtitle {
  margin-top: -0.5rem;
}

.card-group-element-item .card-btn {
  margin-top: 1rem;
}

.frame-background-none .card-group-element .card-title,
.frame-background-light .card-group-element .card-title,
.frame-background-hatched .card-group-element .card-title {
  color: #002a5b;
}

.frame-background-none .card-group-element .card-body::before,
.frame-background-light .card-group-element .card-body::before,
.frame-background-hatched .card-group-element .card-body::before {
  color: #002a5b;
}

@media (min-width: 576px) {
  .card-group-element-columns-2 .card-group-element-item {
    width: calc(99.9% / 2);
  }
}

@media (min-width: 768px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(99.9% / 3);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 992px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 4);
  }
}
